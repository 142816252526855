import Component from 'vue-class-component'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import filter from 'lodash/filter'
import join from 'lodash/join'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import lowerCase from 'lodash/lowerCase'
import { Watch } from 'vue-property-decorator'
import findIndex from 'lodash/findIndex'

// Base Page
import BasePage from '@/pages/Base'

// Services
import CountryService from '@/services/Country'
import CityService from '@/services/City'
import ProvinceService from '@/services/Province'
import AreaService from '@/services/Area'

@Component({})
export default class AreaList extends BasePage {
  constructor() {
    super()
  }

    //table country
    tableHeaders: object[] = [
      {
        text: 'Country Name',
        align: 'left',
        sortable: false,
        value: 'id'
      }
    ]
    tableItems: any[] = []
    tableTotalItems: string | number = 0
    tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
    tablePagination: any = {
      sortBy: 'name',
      page: 1,
      rowsPerPage: 10,
      descending: true,
      totalItems: 0
    }
    tableLoading: boolean = false
    searchFilterParamsCountryKeyword: string = ''

    //table area list
    tableHeadersArea: object[] = [
      {
        text: 'Area Name',
        align: 'left',
        sortable: false,
        value: 'areaname'
      },
      {
        text: 'Type',
        align: 'left',
        sortable: false,
        value: 'type'
      },
      {
        text: 'Area',
        align: 'left',
        sortable: false,
        value: 'area'
      },
      {
            
        text: 'Action',
        align: 'center',
        sortable: false,
        value: 'action',
        class: 'action-table-width'
      }
    ]
    tableAreaList: any[] = []
    tableLoadingArea: boolean = false
    tableTotalItemsArea: string | number = 0
    tableRowsPerPageItemsArea: number[] = [5, 10, 15, 25, 50, 100]
    tablePaginationArea: any = {
      sortBy: 'name',
      page: 1,
      rowsPerPage: 10,
      descending: true,
      totalItems: 0
    }
    pagination: any = {
      sortBy: 'id'
    }

    //add & edit area list
    titleDialog: string = ''
    areaDialog: boolean = false
    useAsEdit: boolean = false
    itemsType: any = ['Cities', 'Provinces']
    areaListData: any = {}

    //getCity & Province
    tableItemSelectArea: any[] = []
    areaitem: any[] = []
    itemAreaKeyword: any = null
    searchAreaDebounce: Function
    tableItemTemp: any[] = []

    //getArea
    global_var_country_id: string
    selectedCountry: string = ''
    selectedDomestiC: any = null
    showDeleteModal: boolean = false
    selectedEditId: string | number = null
    async mounted(){
      this.searchAreaDebounce = debounce(this.getAttachedArea, 500)
    }

    @Watch('tablePagination', { deep: true })
    @Watch('searchInput', { deep: true })
    onChanged() {
      this.$vuetify.goTo(0)
      this.getCountry()
    }

    @Watch('searchInput')
    searchFilterChanged(newData: any) {
      this.tablePagination.page = 1
      this.searchFilterParamsCountryKeyword = newData.search
      this.getCountry()
    }

    @Watch('itemAreaKeyword')
    onitemNameChange() {
      this.searchAreaDebounce()
    }

    closeExpand() {
      forEach((this.$refs as any).tableDomestic.expanded, (item, key) => {
        (this.$refs as any).tableDomestic.expanded[key] = false
      })
    }
    async theTableExpand(params, index) {
      this.getAreas()
      this.selectedCountry = params.id
    }

    async getCountry() {
      const opts: any = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          'sort': this.tablePagination.sortBy,
          'filter[name][like]': this.searchFilterParamsCountryKeyword
        }
      }
      try{
        this.tableLoading = true
        const response: any = await CountryService.getCountries(opts)
        this.global_var_country_id = join(map(response.data.data, 'id'), ',')
        if (response.data.success) {
          this.tableTotalItems = response.data.meta.pagination.total
          this.tableItems = []
          for (let dataCountry of response.data.data) {
            var country = {
              id: dataCountry.attributes.id,
              nameCountry: dataCountry.attributes.name,
              currencyCountry: dataCountry.attributes.currency
            }
            const findIndexCountry = findIndex(this.tableItems, (table) => table.id === country.id)
            if (findIndexCountry === -1 || findIndexCountry === undefined) {
              this.tableItems.push(country)
            } else {
              this.tableItems[findIndexCountry] = country
            }
          }
        }
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoading = false
      }
    }

    async getAreas() {
      try{
        this.tableLoadingArea = true
        const opts: any = {
          params: {
            'page[limit]': this.tablePaginationArea.rowsPerPage,
            'page[num]': this.tablePaginationArea.page,
            'sort': this.tablePaginationArea.sortBy,
            include: 'cities,provinces'
          }
        }
        const response = await AreaService.getArea(opts)
        const responsedata = response.data.data
        const responseincluded = response.data.included
        this.tableTotalItemsArea = response.data.meta.pagination.total
        this.tableAreaList = []
        const data: any = filter(responsedata, areas => Number(areas.attributes.country_id) === Number(this.selectedCountry))
        forEach (data, areas => {
          var idarea = []
          var namearea = []
               
          for (let relationships of areas.relationships[areas.attributes.area_type]){
            idarea.push(responseincluded[areas.attributes.area_type][relationships.id].attributes.id)
            namearea.push(responseincluded[areas.attributes.area_type][relationships.id].attributes.name)
            this.areaitem.push(responseincluded[areas.attributes.area_type][relationships.id].attributes)
          }
          const arealist: any = {
            id: areas.attributes.id,
            name: areas.attributes.name,
            area_type: areas.attributes.area_type,
            areaid: idarea,
            area: namearea.toString(),
            areaitem: this.areaitem
          }
          this.tableAreaList.push(arealist)
        })
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.tableLoadingArea = false
      }
    }

    clickArea(items){
      forEach (items, item =>{
        const dataTemp = find(this.tableItemSelectArea, area => area.id == item)
        this.tableItemTemp.push(dataTemp)
      })
    }

    async getCities() {
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][is]': this.selectedCountry
          }
        }
        if (!isEmpty(this.itemAreaKeyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.itemAreaKeyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await CityService.getCities(opts)
        const responseData = response.data.data
        this.tableItemSelectArea = []
        this.tableItemSelectArea = this.tableItemTemp
        for (const city of responseData) {
          this.tableItemSelectArea.push({
            name: city.attributes.name,
            id: city.attributes.id
          })
        }
        this.tableItemSelectArea = sortBy(this.tableItemSelectArea, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    async getProvinces() {
      try {
        const opts: any = {
          params: {
            'page[num]': 1,
            'page[limit]': 10,
            'filter[name][like]': '',
            'filter[country_id][is]': this.selectedCountry
          }
        }
        if (!isEmpty(this.itemAreaKeyword)) {
          var textFilter = 'filter[name][like]'
          opts.params[textFilter] = this.itemAreaKeyword
        } else {
          var textFilter = 'filter[name][like]'
          delete opts.params[textFilter]
        }
        const response = await ProvinceService.getProvinces(opts)
        const responseData = response.data.data
        this.tableItemSelectArea = []
        this.tableItemSelectArea = this.tableItemTemp
        for (const province of responseData) {
          this.tableItemSelectArea.push({
            name: province.attributes.name,
            id: province.attributes.id
          })
        }
        this.tableItemSelectArea = sortBy(this.tableItemSelectArea, 'name')
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    getAttachedArea() {
      if (lowerCase(this.areaListData.area_type) === 'cities') {
        this.getCities()
      } else if (lowerCase(this.areaListData.area_type) === 'provinces') {
        this.getProvinces()
      }
    }

    getAttachedChange() {
      if (lowerCase(this.areaListData.area_type) === 'cities') {
        this.getCities()
      } else if (lowerCase(this.areaListData.area_type) === 'provinces') {
        this.getProvinces()
      }
      this.tableItemTemp = []
      this.areaListData.areaid = []
    }

    openEditDialog(props: any){
      this.titleDialog = 'Edit Area'
      this.tableItemSelectArea = props.item.areaitem
      this.areaListData = {
        name : props.item.name,
        area_type : startCase(props.item.area_type),
        areaid : props.item.areaid,
        areaitem : props.item.areaitem
      }
      this.useAsEdit = true
      this.areaDialog = true
      this.selectedEditId = props.item.id
    }

    openCreateArea() {
      this.titleDialog = 'Add New Area'
      this.getAttachedArea()
      this.tableItemSelectArea = []
      this.areaListData = {
        name : '',
        area_type : '',
        areaid : [],
        areaitem: []
      }
      this.areaDialog = true
      this.useAsEdit = false
    }

    openDeleteModal(props) {
      this.selectedDomestiC = props.item
      this.showDeleteModal = true
    }

    async addArea(){
      try {
        const validationResponse = await this.$validator.validateAll('addNewArea')
        if (validationResponse) {
          this.showLoading({ text: 'Creating...' })
          this.areaDialog = false
          const areaListData = {
            name: this.areaListData.name,
            scope:[
              {
                ids: this.areaListData.areaid,
                type: lowerCase(this.areaListData.area_type)
              }
            ],
            country_id: this.selectedCountry
          }
          await AreaService.createArea(areaListData)
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
        } else {
          this.showSnackbar({
            text: 'Please check all fields requirements',
            color: 'red',
            timeout: 2000
          })
        }
        this.getAreas()
      }  catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    async updateArea(){
      try {
        const validationResponse = await this.$validator.validateAll('addNewArea')
        if (validationResponse) {
          this.showLoading({ text: 'Updating...' })
          this.areaDialog = false
          const areaList = {
            name: this.areaListData.name,
            scope:[
              {
                ids: this.areaListData.areaid,
                type: lowerCase(this.areaListData.area_type)
              }
            ],
            country_id: this.selectedCountry
          }
          await AreaService.updateArea(this.selectedEditId, areaList)
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
        } else {
          this.showSnackbar({
            text: 'Please check all fields requirements',
            color: 'red',
            timeout: 2000
          })
          this.useAsEdit = false
          this.areaDialog = false
        }
        this.getAreas()
      }  catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }

    async deleteArea() {
      try {
        this.showLoading({ text: 'Saving...' })
        this.showDeleteModal = false
        const response = await AreaService.deleteArea(this.selectedDomestiC.id)
        this.showSnackbar({
          text: startCase(response.status === 200 ? 'Success' : 'Failed with HTTP error: ' + response.status),
          color: response.status === 200 ? 'green' : 'red',
          timeout: 1500
        })
        this.getAreas()
      } catch (error) {
        this.catchHandler(error)
      } finally {
        this.closeLoading()
      }
    }
}