import BaseService from '@/services/BaseService'

class AreaService extends BaseService {
  constructor() {
    super()
  }

  async createArea(data = {}) {
    this.endPoint = 'api/v2/areas'
    return this.post(data)
  }

  async updateArea(id, data = {}) {
    this.endPoint = 'api/v2/areas'
    return this.putOne(id, data)
  }

  async getArea(opts = {}){
    this.endPoint = 'api/v2/areas'
    return this.get(opts)
  }

  async deleteArea(id) {
    this.endPoint = 'api/v2/areas'
    return this.delete(id)
  }
}
export default new AreaService()